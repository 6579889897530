import { PencilIcon } from "@heroicons/react/24/solid";
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  Input,
} from "@material-tailwind/react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";

const TABLE_HEAD = [
  "S.No",
  "UserId",
  "User Name",
  "Email",
  "Phone No",
  "Register Date",
  "Request Coins",
  "Status",
  "Action",
];

function Coins() {
  const [data, setData] = useState([]);

  const getCoins = async () => {
    try {
      await axios
        .get("https://justbuygold.co.in/api/v1/coins/getCoins")
        .then((res) => {
          if (res.status === 200) {
            setData(res.data.success);
            // console.log(res.data.success);
          } else {
            console.log(res.status.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCoins();
  }, []);

  // Customer Approved+++++++++++++++++++++++++++++++++++++++++++++++++++++

  const customerApproved = async (id, status) => {
    try {
      const config = {
        url: "/approvedhold",
        method: "put",
        baseURL: "https://justbuygold.co.in/api/v1/coins/",
        headers: { "content-type": "application/json" },
        data: {
          id: id,
          status: status,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data.success);
        alert(`${res.data.success}`);
        window.location.reload();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // pagination+++++++++++++++++
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 5;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = data.slice(visitedPage, visitedPage + productPerPage);

  const pageCount = Math.ceil(data.length / productPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = data.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setData([...filterTable]);
    } else {
      setSearch(e.target.value);
      setData([...data]);
    }
  };

  return (
    <>
      <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-4 flex flex-col justify-between gap-5 md:flex-row md:items-center">
            <div>
              <Typography variant="h5" color="blue-gray">
                Request Coins
              </Typography>
            </div>
            <div>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    class="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  value={search}
                  onChange={handleFilter}
                  id="default-search"
                  class="block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search user details...."
                  style={{
                    padding: "0.75rem",
                    paddingLeft: "31px",
                    outlineWidth: "0px",
                  }}
                  required
                />
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="text-base leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayPage.map((ele, index) => {
                const isLast = index === data.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={ele?._id}>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {pageNumber * productPerPage + (index + 1)}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?._id}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?.username}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?.email}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?.phone}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {moment(ele?.createdAt).format("MMM Do YY")}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?.coins}gm
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?.status}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="w-max ">
                        <p
                          style={{
                            color: "white",
                            cursor: "pointer",
                            border: " 1px solid red",
                            padding: " 2px 10px",
                            backgroundColor: " red",
                            borderRadius: "5px",
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            customerApproved(ele?._id, "Approved");
                          }}
                        >
                          Approved
                        </p>

                        <p
                          style={{
                            color: "white",
                            cursor: "pointer",
                            border: " 1px solid green",
                            padding: " 2px 10px",
                            backgroundColor: " green",
                            borderRadius: "5px",
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            customerApproved(ele?._id, "Hold");
                          }}
                        >
                          Hold
                        </p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>

        <ReactPaginate
          style={{ display: "flex", gap: "20px" }}
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </Card>
    </>
  );
}

export default Coins;
