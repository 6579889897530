import React from "react";
import { Sidebar } from "./Sidebar";
import Header from "./Header";

function Main(props) {
  return (
    <div className="dash">
      <div className="row me-0">
        <div className="col-md-2">
          <div
            className="left-side"
            style={{
              backgroundColor: "#a05b09",
              position: "sticky",
              top: "0",
            }}
          >
            <Sidebar />
          </div>
        </div>

        <div className="col-md-10  right-h ">
          {/* <Header /> */}
          <div
            style={{
              height: "auto",
              minHeight: "100vh",
              // background: "#ecf0f5",
            }}
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
