import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
} from "@material-tailwind/react";
import {
  PresentationChartBarIcon,
  ShoppingBagIcon,
  UserCircleIcon,
  Cog6ToothIcon,
  InboxIcon,
  PowerIcon,
} from "@heroicons/react/24/solid";
import "./admin.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

export function Sidebar() {
  // Customer++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [user, setUser] = useState([]);
  const getUser = async () => {
    try {
      await axios
        .get("https://justbuygold.co.in/api/v1/user/auth/getalluser")
        .then((res) => {
          if (res.status === 200) {
            setUser(res.data.success);
            console.log(res.data.success);
          } else {
            alert("Somthing wrong");
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Transaction++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [data, setData] = useState([]);

  const getTransaction = async () => {
    try {
      await axios
        .get("https://justbuygold.co.in/api/v1/transactions/alltransaction")
        .then((res) => {
          if (res.status == 200) {
            setData(res.data.success);
          } else {
            console.log(res.error);
            alert("Something went wrong");
          }
        });
    } catch (error) {
      console.log(error.success);
    }
  };

  // Coin request+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [datas, setDatas] = useState([]);

  const getCoins = async () => {
    try {
      await axios
        .get("https://justbuygold.co.in/api/v1/coins/getCoins")
        .then((res) => {
          if (res.status === 200) {
            setDatas(res.data.success);
            console.log(res.data.success);
          } else {
            console.log(res.status.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
    getTransaction();
    getCoins();
  }, []);

  let adminuser = JSON.parse(sessionStorage.getItem("admin"));
  // console.log(adminuser);

  const removeuser = () => {
    sessionStorage?.removeItem("admin");
    window.location.assign("/");
  };

  return (
    <div className="dfoewh">
      <Card className="h-[calc(100vh)] w-full max-w-[17rem]  shadow-xl shadow-blue-gray-900/5 bgv">
        <div className="mb-2 ">
          <Typography variant="h5" color="blue-gray">
            <img src="../Buygold.jpg" alt="" style={{ width: "270px" }} />
          </Typography>
        </div>
        <List className="jfdoweh">
          <ListItem className="buygold hover:bg-slate-100 ">
            <Link to="/gold-rate" className="side-admin font-semibold">
              {" "}
              <ListItemPrefix>
                <ShoppingBagIcon className="h-5 w-5" />
              </ListItemPrefix>
              Gold Rate
            </Link>
          </ListItem>
          <ListItem className="buygold hover:bg-slate-100">
            <Link to="/gst" className="side-admin font-semibold">
              <ListItemPrefix>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
                  />
                </svg>
              </ListItemPrefix>
              GST %
              <ListItemSuffix>
                <Chip
                  //   value="14"
                  size="sm"
                  variant="ghost"
                  color="blue-gray"
                  className="rounded-full"
                />
              </ListItemSuffix>
            </Link>
          </ListItem>
          <ListItem className="buygold hover:bg-slate-100">
            <Link to="/promovideo" className="side-admin font-semibold">
              <ListItemPrefix>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    stroke-linecap="round"
                    d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
                  />
                </svg>
              </ListItemPrefix>
              Promo videos
              <ListItemSuffix>
                <Chip
                  //   value="14"
                  size="sm"
                  variant="ghost"
                  color="blue-gray"
                  className="rounded-full"
                />
              </ListItemSuffix>
            </Link>
          </ListItem>
          <ListItem className="buygold hover:bg-slate-100">
            <Link to="/coins" className="side-admin font-semibold">
              <ListItemPrefix>
                <InboxIcon className="h-5 w-5" />
              </ListItemPrefix>
              Coins
              <ListItemSuffix>
                <span class="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                  {datas.length}
                </span>
              </ListItemSuffix>
            </Link>
          </ListItem>
          <ListItem className="buygold hover:bg-slate-100">
            <Link to="/customer-details" className="side-admin font-semibold">
              <ListItemPrefix>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                  />
                </svg>
              </ListItemPrefix>
              Customer details
              <ListItemSuffix>
                <span class="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                  {user.length}
                </span>
                {/* <Chip
                  value="14"
                  size="sm"
                  variant="ghost"
                  color="blue-gray"
                  className="rounded-full"
                /> */}
              </ListItemSuffix>
            </Link>
          </ListItem>
          <ListItem className="buygold hover:bg-slate-100">
            <Link
              to="/transcation-history"
              className="side-admin font-semibold"
            >
              <ListItemPrefix>
                <InboxIcon className="h-5 w-5" />
              </ListItemPrefix>
              Transaction History
              <ListItemSuffix>
                <span class="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                  {data.length}
                </span>
              </ListItemSuffix>
            </Link>
          </ListItem>
          <ListItem className="buygold hover:bg-#facc15-100">
            <Link
              to="#"
              className="side-admin text-base font-semibold"
              onClick={() => {
                removeuser();
              }}
            >
              <ListItemPrefix>
                <PowerIcon className="h-5 w-5" />
              </ListItemPrefix>
              Log Out
            </Link>
          </ListItem>
        </List>
      </Card>
    </div>
  );
}
