import { PencilIcon } from "@heroicons/react/24/solid";
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  Input,
} from "@material-tailwind/react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";

const TABLE_HEAD = ["S.No", "SGST", "CGST", "Total GST", "Action"];

const TABLE_ROWS = [
  {
    name: "9%",
    amount: "9%",
    totalgst: "18%",
  },
  {
    name: "9%",
    amount: "9%",
    totalgst: "18%",
  },
];

function Gst() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [data, setData] = useState([]);
  const [dataObj, setDataObj] = useState({});
  // const [totalTax, setTotalTax] = useState(0);

  const getGst = async () => {
    try {
      await axios
        .get("https://justbuygold.co.in/api/v1/gst/getGst")
        .then((res) => {
          if (res.status === 200) {
            setDataObj(res.data.success);
            if (data?.length === 0) {
              data.push(res.data.success);
            }
          } else {
            alert("Somthing wrong");
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  //  Add GST
  const [cgst, setCGST] = useState("");
  const [sgst, setSGST] = useState("");
  const addGst = async () => {
    try {
      const config = {
        url: "/addgst",
        method: "post",
        baseURL: "https://justbuygold.co.in/api/v1/gst",
        headers: { "content-type": "application/json" },
        data: {
          Sgst: sgst,
          Cgst: cgst,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data.success);
        alert("Gst Added");
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  useEffect(() => {
    getGst();
  }, []);

  console.log("data", data);
  return (
    <>
      <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
            <div>
              <Typography variant="h5" color="blue-gray">
                GST
              </Typography>
            </div>
            <div className="flex w-full shrink-0 gap-1 md:w-max">
              <Button
                onClick={handleShow}
                className="flex items-center text-white gap-1"
                size="sm"
                style={{ backgroundColor: "#a05b09" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z"
                    clipRule="evenodd"
                  />
                </svg>
                GST
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="text-base leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((ele, index) => {
                const isLast = index === data.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={ele}>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {index + 1}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?.Sgst}%
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?.Cgst}%
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?.Sgst + ele?.Cgst}%
                      </Typography>
                    </td>

                    <td className={classes}>
                      {/* <Tooltip content="Edit Video">
                        <IconButton variant="text" onClick={handleShow1}>
                          <PencilIcon className="h-4 w-4 gap-2" />
                        </IconButton>
                      </Tooltip> */}
                      <Tooltip content="Delete Video">
                        <IconButton variant="text" className="relativeds">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className=" pl-2 h-6 w-6"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>

      {/* Add Video */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <Typography variant="h5" color="blue-gray">
              GST
            </Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-data">
            <Form>
              <div className="aaa mb-2">
                <label style={{ fontSize: "20px", paddingBottom: "8px" }}>
                  SGST
                </label>
                <br />
                <input
                  type="text"
                  placeholder="Add your SGST"
                  className="input-add"
                  value={sgst}
                  onChange={(e) => {
                    setSGST(e.target.value);
                  }}
                />
              </div>
              <div className="aaa mb-2">
                <label style={{ fontSize: "20px", paddingBottom: "8px" }}>
                  CGST
                </label>
                <br />
                <input
                  type="text"
                  placeholder="Add your CGST"
                  className="input-add"
                  value={cgst}
                  onChange={(e) => {
                    setCGST(e.target.value);
                  }}
                />
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="px-3"
            size="sm"
            style={{ backgroundColor: "#a05b09" }}
            onClick={handleClose}
          >
            Close
          </Button>
          {/* <Button variant="gradient" style={{ backgroundColor: "#a05b09" }}>
            gradient
          </Button> */}
          <Button
            className="px-3"
            size="sm"
            style={{ backgroundColor: "#a05b09" }}
            onClick={addGst}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Gst;
