import React, { useState } from "react";
import axios from "axios";

function Login() {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const adminLogin = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/login",
        method: "post",
        baseURL: "https://justbuygold.co.in/api/v1/admin",
        headers: { "content-type": "application/json" },
        data: {
          email: email,
          password: password,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        sessionStorage.setItem("admin", JSON.stringify(res.data.success));
        alert("Successfully Login");
        window.location.assign("/gold-rate");
      }
    } catch (error) {
      alert(error.response.data.error);
      // console.log("Error==>", error);
    }
  };

  return (
    <>
      <div>
        <section class="rounded-md  p-2">
          <div class="flex items-center justify-center bg-white px-4 py-10 sm:px-6 sm:py-16 lg:px-8">
            <div
              class="xl:mx-auto xl:w-full xl:max-w-sm 2xl:max-w-md"
              style={{
                boxShadow:
                  " 0 1px 1px rgba(0,0,0,0.15) ,0 2px 2px rgba(0,0,0,0.15), 0 4px 4px rgba(0,0,0,0.15), 0 8px 8px rgba(0,0,0,0.15)",
                padding: "15px",
                width: "",
              }}
            >
              <div
                class="mb-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src="../Buygold.jpg"
                  alt=""
                  style={{ width: "230px", height: "110px" }}
                />
              </div>
              <h2 class="text-2xl font-bold leading-tight text-black">
                Sign in to your account
              </h2>

              <form class="mt-8" onSubmit={adminLogin}>
                <div class="space-y-5">
                  <div>
                    <label for="" class="text-base font-medium text-gray-900">
                      {" "}
                      Email address{" "}
                    </label>
                    <div class="mt-2">
                      <input
                        class="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                          setemail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="flex items-center justify-between">
                      <label for="" class="text-base font-medium text-gray-900">
                        {" "}
                        Password{" "}
                      </label>
                    </div>
                    <div class="mt-2">
                      <input
                        class="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setpassword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      class="inline-flex w-full items-center justify-center rounded-md  px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80"
                      style={{ backgroundColor: "#a05b09" }}
                    >
                      Sign in{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="ml-2"
                      >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
