import { PencilIcon } from "@heroicons/react/24/solid";
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  Input,
} from "@material-tailwind/react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ReactPaginate from "react-paginate";

const TABLE_HEAD = [
  "S.No.",
  "User Name",
  "User Id",
  "Phone No",
  "Amount",
  "Payment Date",
  "Payment Id",
  "Store Gold",
  "Status",
];

function TranscationHistory() {
  const [data, setData] = useState([]);
  console.log("efnwofnorewnfonier", data);
  const [nochangedata, setnochangedata] = useState([]);

  const getTransaction = async () => {
    try {
      await axios
        .get("https://justbuygold.co.in/api/v1/transactions/alltransaction")
        .then((res) => {
          if (res.status == 200) {
            setData(res.data.success);
            setnochangedata(res.data.success);
          } else {
            console.log(res.error);
            alert("Something went wrong");
          }
        });
    } catch (error) {
      console.log(error.success);
    }
  };

  useEffect(() => {
    getTransaction();
  }, []);

  // Search filter======
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setData([...filterTable]);
    } else {
      setSearch(e.target.value);
      setData([...nochangedata]);
    }
  };

  // Date filter
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  const filterData = () => {
    if (!startDate) return alert("Please select from date");
    if (!endDate) return alert("Please select to date");
    const filteredData = nochangedata.filter((item) => {
      const itemDate = new Date(item?.createdAt);
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      return itemDate >= startDateObj && itemDate <= endDateObj;
    });
    setData([...filteredData]);
  };

  // pagination+++++++++++++++++
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 5;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = data.slice(visitedPage, visitedPage + productPerPage);

  const pageCount = Math.ceil(data.length / productPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const tableRef = useRef(null);

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <Typography variant="h5" color="blue-gray">
              Transactions
            </Typography>
          </div>
        </div>
        <div className="mb-4 flex flex-col  gap-8 md:flex-row md:items-center">
          <div className="flex w-full shrink-0 gap-3 md:w-max">
            <div className="w-full md:w-72">
              <div class="relative">
                <label for="simple-search">Search</label>
                <div class="absolute inset-y-0 left-0 top-5 flex items-center pl-3 pointer-events-none">
                  <svg
                    class="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  value={search}
                  class="block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search transactions details...."
                  style={{ padding: "0.75rem", paddingLeft: "31px" }}
                  required
                  onChange={handleFilter}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full shrink-0 gap-3 md:w-max">
            <div className="md:w-40">
              <label for="simple-search">From Date</label>
              <input
                type="date"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm 
                rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={startDate}
                onChange={(e) => setstartDate(e.target.value)}
              ></input>
            </div>
            <div className="md:w-40">
              <label for="simple-search">To Date</label>
              <input
                type="date"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={endDate}
                onChange={(e) => setendDate(e.target.value)}
              ></input>
            </div>
            <div
              className="flex w-full shrink-0 gap-1 md:w-max"
              style={{
                marginTop: "20px",
                marginBottom: "-16px",
              }}
            >
              <p
                className="flex items-center text-white gap-1"
                size="sm"
                style={{
                  backgroundColor: "#a05b09",
                  padding: " 0px 28px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  filterData();
                }}
              >
                Submit
              </p>
            </div>

            <div
              className="flex w-full shrink-0 gap-1 md:w-max"
              style={{
                marginTop: "18px",
                // marginBottom: "-16px",
                backgroundColor: "#a05b09",
                cursor: "pointer",
              }}
            >
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={tableRef.current}
              >
                <p
                  className="flex items-center text-white gap-1"
                  size="sm"
                  style={{
                    backgroundColor: "#a05b09",
                    padding: " 0px 22px",
                    marginTop: "9px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                  Download Excel
                </p>
              </DownloadTableExcel>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="w-full min-w-max table-auto text-left" ref={tableRef}>
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-base leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {displayPage?.map((ele, index) => {
              const isLast = index === data.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <tr key={index}>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-center"
                    >
                      {pageNumber * productPerPage + (index + 1)}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold"
                      >
                        {ele?.UserId?.name}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {ele?.UserId?._id}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {ele?.UserId?.phoneno}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {ele?.amount}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {moment(ele?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {ele?.PaymentId}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-center"
                    >
                      {ele?.gold}gm
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      className="font-normal text-center"
                      style={{
                        color: "white",
                        cursor: " pointer",
                        border: "1px solid green",
                        padding: " 2px 10px",
                        backgroundColor: "green",
                        borderRadius: " 5px",
                        fontSize: "14px",
                        textTransform: " capitalize",
                      }}
                    >
                      {ele?.status}
                    </Typography>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardBody>
      <ReactPaginate
        style={{ display: "flex", gap: "20px" }}
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </Card>
  );
}

export default TranscationHistory;
